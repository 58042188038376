import { useEffect, useState } from "react"
import { useFirebase } from "../providers/FirebaseProvider"
import {
  query as firestoreQuery,
  orderBy,
  onSnapshot,
  where,
  collectionGroup,
  Timestamp,
  limit,
} from "firebase/firestore"
import { SubmissionDocument } from "@ap-frontend/types"
import { useUser } from "../providers/UserProvider"

export interface FailedSubmissionAttempt extends SubmissionDocument {
  applicationNumber: string
  id: string
}

const useFailedSubmissionAttempts = (recordLimit: number, time: Timestamp) => {
  const [data, setData] = useState<FailedSubmissionAttempt[]>([])

  const { admin } = useUser()

  const { firestore } = useFirebase()

  useEffect(() => {
    if (!firestore) return
    if (!admin.access || !admin?.roles?.audit) {
      throw new Error(
        "User does not have permission to access failed submission attempts."
      )
    }

    const query = firestoreQuery(
      collectionGroup(firestore, "submissions"),
      where("latest", "==", true),
      where("status", "in", ["error", "in-progress"]),
      where("timeout", "<=", time),
      orderBy("timeout", "asc"),
      limit(recordLimit)
    )

    const unsubscribe = onSnapshot(query, QuerySnapshot => {
      const submissions: FailedSubmissionAttempt[] = []
      QuerySnapshot.forEach(doc => {
        const path = doc.ref.path
        const applicationNumber = path.match(/\/(\d+)\/submissions\//)?.[1]
        submissions.push({
          ...doc.data(),
          id: doc.id,
          applicationNumber: applicationNumber,
        } as unknown as FailedSubmissionAttempt)
      })

      setData(submissions)
    })

    return () => {
      unsubscribe()
    }
  }, [firestore, admin, recordLimit, time])

  return data
}

export default useFailedSubmissionAttempts
